// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeAnalytics } from './utils/analytics';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/layout';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

// Import your pages
import SubscriptionLandingpage from './pages/subscription/SubscriptionLandingpage';
import Login from './pages/login/login';
import ChangePassword from './pages/change-password/change-password';
import ResetPassword from './pages/change-password/reset-password';
import ForgotPassword from './pages/forgot-password/forgot-password';
import ProfileUpdate from './pages/profile-update/profile-update';
import ThankYouPage from './pages/thankyou/ThankYouPage';
import PrivateRoute from './components/PrivateRoute';
import Reports from './pages/reports/reports';
import Dictionary from './pages/dictionary/Dictionary';

// Create a separate component for routes to use useLocation
function AppRoutes() {
  React.useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics();
  }, []);

  return (
    <Layout>
      <Routes>
        <Route 
          path="/newsletters/*"
          element={
            <StaticFileHandler />
          }
        />
        
        <Route path="/" element={<SubscriptionLandingpage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
        <Route
          path="/profile-update"
          element={
            <PrivateRoute>
              <ProfileUpdate isReadOnly={false} />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfileUpdate isReadOnly={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Reports />  {/* Display Reports only for authenticated users */}
            </PrivateRoute>
          }
        />
        <Route path="/dictionary" element={<Dictionary />} />
        {/* Add other routes here as needed */}
      </Routes>
    </Layout>
  );
}

function StaticFileHandler() {
  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith('/newsletters/')) {
      window.location.href = path;
    }
  }, []);
  return null;
}

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <AppRoutes />
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
