// src/services/apiService.js

import axiosInstance from './axiosConfig';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const S3_BASE_URL = 'https://gateway.fs.vaidhyamegha.com/file-navigator';

// Set up Axios interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    // console.log('Request Headers:', config.headers); // Log the headers
    return config;
  },
  (error) => Promise.reject(error)
);

// Ensure FormData is correctly sent as multipart/form-data
export const createProfile = (data) => {
  return axiosInstance.post(`${API_BASE_URL}/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [(data) => data],  // Prevent Axios from transforming FormData
  });
};

export const updateProfile = (subscriberId, data) => {
  return axiosInstance.put(`${API_BASE_URL}/${subscriberId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [(data) => data],  // Prevent Axios from transforming FormData
  });
};

export const getProfile = (subscriberId) => {
  return axiosInstance.get(`${API_BASE_URL}/${subscriberId}`);
};

export const saveFormDataToBackend = (data, subscriberId) => {
  return axiosInstance.post(`${API_BASE_URL}/save-form-data/${subscriberId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getSavedFormData = (subscriberId) => {
  return axiosInstance.get(`${API_BASE_URL}/get-saved-form-data/${subscriberId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteSavedFormData = (subscriberId) => {
  return axiosInstance.delete(`${API_BASE_URL}/delete-saved-form-data/${subscriberId}`);
};

// Function to fetch data from S3
export const fetchS3Data = async (params) => {
  try {
    const response = await axiosInstance.get(S3_BASE_URL, {
      headers: {
        'X-Allowed': 'true',
        // 'X-TenantID': 'dell',
        'Authorization': `Bearer ${process.env.REACT_APP_JWT_TEMP_TOKEN}`,
      },
      params,
    });
    return response.data;
  } catch (error) {
    // console.error('Error fetching S3 data:', error);
    throw error;
  }
};
