import { useState, useEffect, useCallback } from 'react';
import Papa from 'papaparse';

export const useTerminologyData = () => {
    const [terms, setTerms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadTerminology = useCallback(async () => {
        try {
            const response = await fetch('/data/clinical_trails_terminology.csv');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const csvData = await response.text();
            
            Papa.parse(csvData, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    if (result.data && result.data.length > 0) {
                        const processedData = result.data.map((term, index) => ({
                            ...term,
                            id: `term-${index}`,
                            searchableText: `${term.Term} ${term.Description}`.toLowerCase()
                        }));
                        setTerms(processedData);
                        setLoading(false);
                    } else {
                        setError('No data found in CSV file');
                        setLoading(false);
                    }
                },
                error: (error) => {
                    setError('Error parsing CSV file');
                    setLoading(false);
                }
            });
        } catch (err) {
            setError(`Error loading terminology data: ${err.message}`);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadTerminology();
    }, [loadTerminology]);

    return { terms, loading, error };
}; 