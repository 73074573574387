import React from 'react';
import FrameComponent from './FrameComponent';
import Footer from './Footer';

const Layout = ({ children, isLoggedIn, setIsLoggedIn }) => {
  return (
    <div style={{ 
      width: '100%', 
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}>
      <FrameComponent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <main style={{ flex: 1, width: '100%' }}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
