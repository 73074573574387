import React, { useState, useMemo, useEffect } from 'react';
import DictionarySearch from './DictionarySearch';
import TermItem from './TermItem';
import { useTerminologyData } from './useTerminologyData';
import './Dictionary.css';

const Dictionary = () => {
    const [inputValue, setInputValue] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const { terms, loading, error } = useTerminologyData();

    useEffect(() => {
        const timer = setTimeout(() => {
            setFilterValue(inputValue);
        }, 150);

        return () => clearTimeout(timer);
    }, [inputValue]);

    const highlightText = (text, highlight) => {
        if (!highlight.trim()) return text;
        
        const regex = new RegExp(`(${highlight})`, 'gi');
        return text.split(regex).map((part, index) => 
            regex.test(part) ? (
                <span key={index} className="highlight">{part}</span>
            ) : (
                part
            )
        );
    };

    const filteredAndSortedTerms = useMemo(() => {
        if (!filterValue) {
            return [...terms].sort((a, b) => a.Term.localeCompare(b.Term));
        }
        
        const searchLower = filterValue.toLowerCase();
        const filtered = terms.filter(term => 
            term.searchableText.includes(searchLower)
        );

        return filtered.sort((a, b) => a.Term.localeCompare(b.Term));
    }, [filterValue, terms]);

    if (loading) return (
        <div className="dictionary-page">
            <div className="dictionary-loading">
                <div className="loading-spinner"></div>
                <p>Loading terminology...</p>
            </div>
        </div>
    );

    if (error) return (
        <div className="dictionary-page">
            <div className="dictionary-error">
                <p>{error}</p>
                <button onClick={() => window.location.reload()} className="retry-button">
                    Retry
                </button>
            </div>
        </div>
    );

    return (
        <div className="dictionary-page">
            <div className="dictionary-banner">
                <div className="banner-overlay">
                    <h1 className="banner-title">Clinical Trial Terminology</h1>
                    <p className="banner-description">
                        Explore our comprehensive dictionary of clinical trial terms to better
                        understand medical research and healthcare studies.
                    </p>
                </div>
            </div>
            <div className="dictionary-container">
                <DictionarySearch 
                    searchTerm={inputValue} 
                    setSearchTerm={setInputValue} 
                />
                <div className="dictionary-content">
                    {filteredAndSortedTerms.length === 0 ? (
                        <div className="no-results">
                            <p>No matching terms found for "{filterValue}"</p>
                            <button 
                                onClick={() => {
                                    setInputValue('');
                                    setFilterValue('');
                                }}
                                className="clear-search"
                            >
                                Clear Search
                            </button>
                        </div>
                    ) : (
                        <div 
                            className="terms-list"
                            role="region"
                            aria-label="Clinical terms list"
                            id="search-results"
                        >
                            {filteredAndSortedTerms.map((term, index) => (
                                <TermItem 
                                    key={term.id} 
                                    term={term}
                                    searchTerm={filterValue}
                                    highlightText={highlightText}
                                    index={index}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Dictionary;