// src/services/axiosConfig.js

import axios from 'axios';
import { getCookie } from './cookieService';

// Create an Axios instance
const axiosInstance = axios.create({
  withCredentials: true, // Include cookies if necessary
});

// Request interceptor to add Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    //console.log('AxiosConfig: Intercepting request for:', config.url);
    
    // Add X-allowed header to all requests
    config.headers['X-Allowed'] = 'true';

    // Exclude routes that don't need Authorization
    const excludedRoutes = ['/login', '/forgot-password', '/reset-password', '/reports'];
    const shouldExclude = excludedRoutes.some((route) => config.url.includes(route));

    // Only add Authorization header if the route isn't excluded
    if (!shouldExclude) {
      const token = getCookie('token'); // Retrieve the JWT token from cookies
      //console.log('AxiosConfig: Token used in request:', token);
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    // Check if the request is for the S3 endpoint
    if (config.url.includes('file-navigator')) {
      const envToken = process.env.REACT_APP_JWT_TEMP_TOKEN;
      //console.log('Using env token for S3 request:', envToken);
      config.headers.Authorization = `Bearer ${envToken}`;
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 Unauthorized responses
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status;
    const errorMessage = error.response?.data?.error || error.message;
    
    if (status === 401) {
      //console.log('AxiosConfig: Unauthorized request detected:', errorMessage);

      if (errorMessage === "Token has expired") {
        //console.log('AxiosConfig: Token expired. Redirecting to login.');
        // You can use a global logout handler here if needed
      } else {
        // console.log('AxiosConfig: Unauthorized due to invalid credentials.');
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
