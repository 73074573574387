import PropTypes from "prop-types";
import "./Footer.css";
import "../global.css"
import { trackFooterLinkClick, trackCrossDomainNavigation } from '../utils/analytics';

const Footer = ({ className = "" }) => {
  const handleExternalLinkClick = (url, linkType) => {
    trackFooterLinkClick(linkType, url);
    trackCrossDomainNavigation(url);
  };

  return (
    <footer className={`footer ${className}`}>
      <div className="footer-child" />
      <div className="footer-container">
        <div className="footer-content1">
          <img
            className="final-logo-csdlc-11"
            alt=""
            src="/subscription/final-logo-csdlc-1-1.svg"
          />
          <div className="footer-details">
            <div className="company-details">
              <div className="company-divider" />
              <div className="company-info">
                <div className="csdlcorg-is-powered-container">
                  <span>{`CSDLC.org is Powered by VaidhyaMegha Pvt Ltd., an `}</span>
                  <span className="iso-27001">{`ISO 9001 : 2015 &  ISO 27001 : 2022 Certified company`}</span>
                </div>
                <div className="certification">
                  <img
                    className="iso-9001-2015-1-icon"
                    loading="lazy"
                    alt=""
                    src="/subscription/iso-90012015-1@2x.png"
                  />
                  <div className="certification-inner">
                    <div className="line-div" />
                  </div>
                  <img
                    className="iso-27001-2022-3-icon"
                    loading="lazy"
                    alt=""
                    src="/subscription/iso-270012022-3@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="contact-divider">
            <div className="contact-divider-child" />
          </div>
          <div className="contact-details-wrapper">
            <div className="contact-details">
              <h3 className="reach-us">Reach us</h3>
              <div className="contact-options">
                <div className="email-details-parent">
                  <div className="email-details">
                    <div className="email-content">
                      <div className="email-icons">
                        <a href="tel:+919618986039">
                          <img
                            className="vector-icon4"
                            alt="Phone"
                            src="/subscription/vector-4.svg"
                          />
                        </a>
                        <a href="mailto:contact@vaidhyamegha.com">
                          <img
                            className="vector-icon5"
                            alt="Email"
                            src="/subscription/vector-5.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="email-info">
                      <a href="tel:+919618986039" className="email-label">
                        +91 9618986039
                      </a>
                      <a href="mailto:contact@vaidhyamegha.com" className="email-address">
                        <div className="contactvaidhyameghacom">
                          contact@vaidhyamegha.com
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="location-details-wrapper">
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=3-5-139%2F3%2F3%2F2%2C+Shiva+Nagar+Colony%2C+Siva+Nagar%2C+Hyderguda%2C+Hyderabad%2C+Telangana+500048"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="location-details"
                    >
                      <div className="location-content">
                        <img
                          className="location-icon"
                          loading="lazy"
                          alt="Location"
                          src="/subscription/location.svg"
                        />
                      </div>
                      <div className="shiva-nagar-colony-container">
                        <p className="shiva-nagar-colony">
                          3-5-139/3/3/2, Shiva Nagar Colony,
                        </p>
                        <p className="hyderguda-attapur-hyderabad">
                          Hyderguda, Attapur, Hyderabad,
                        </p>
                        <p className="telangana-500048">Telangana - 500048</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="legal-divider-parent">
        <div className="legal-divider" />
        <div className="legal-details">
          <div className="legal-info">
            <div className="copyright-2018-">
              © Copyright 2018 - 24 VaidhyaMegha Pvt. Ltd. All Rights Reserved.
            </div>
            <div className="privacy-policy-container">
              <a
                className="privacy-policy"
                href="https://vaidhyamegha.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleExternalLinkClick('https://vaidhyamegha.com/privacy-policy', 'privacy_policy')}
              >
                Privacy Policy
              </a>
              <a
                className="terms-conditions"
                href="https://vaidhyamegha.com/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleExternalLinkClick('https://vaidhyamegha.com/terms-and-conditions', 'terms_conditions')}
              >{`Terms & Conditions`}</a>
              <a
                className="refund-and-cancellation"
                href="https://vaidhyamegha.com/refund-and-cancellation-policy"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleExternalLinkClick('https://vaidhyamegha.com/refund-and-cancellation-policy', 'refund_cancellation')}
              >
                Refund and Cancellation Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
