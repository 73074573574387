import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TermItem = memo(({ term, searchTerm, highlightText, index }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.target.click();
        }
    };

    return (
        <div 
            className="term-item"
            role="article"
            tabIndex={0}
            aria-labelledby={`term-title-${term.id}`}
            onKeyDown={handleKeyDown}
        >
            <h3 
                id={`term-title-${term.id}`}
                className="term-title"
                tabIndex={0}
            >
                {highlightText(term.Term, searchTerm)}
            </h3>
            <p 
                className="term-description"
                tabIndex={0}
                aria-label={`Description for ${term.Term}`}
            >
                {highlightText(term.Description, searchTerm)}
            </p>
        </div>
    );
});

TermItem.propTypes = {
    term: PropTypes.shape({
        id: PropTypes.string.isRequired,
        Term: PropTypes.string.isRequired,
        Description: PropTypes.string.isRequired
    }).isRequired,
    searchTerm: PropTypes.string,
    highlightText: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default TermItem; 