import React from 'react';
import PropTypes from 'prop-types';

const DictionarySearch = ({ searchTerm, setSearchTerm }) => {
    return (
        <div className="dictionary-search">
            <label htmlFor="search-input" className="visually-hidden">
                Search clinical terminology
            </label>
            <input
                id="search-input"
                type="text"
                placeholder="Search terms..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
                aria-label="Search clinical terminology"
                aria-controls="search-results"
                aria-describedby="search-description"
            />
            <span id="search-description" className="visually-hidden">
                Type to search through clinical terms and descriptions
            </span>
        </div>
    );
};

DictionarySearch.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    setSearchTerm: PropTypes.func.isRequired
};

export default DictionarySearch;